import React from "react";
import { Route, Switch } from "react-router-dom";
import { CampaignsLoadingDialog } from "./campaigns-loading-dialog/CampaignsLoadingDialog";
import { CampaignsUIProvider } from "./CampaignsUIContext";
import { CampaignsCard } from "./CampaignsCard";
import { CampaignDeleteDialog } from "../../../app/pages/campaigns/campaign-delete-dialog/CampaignDeleteDialog";
import { CreateCampaignCard } from "../../../_metronic/campaignAdmin/CreateCampaign/createCampaignCard";

export function CampaignsPage({ history }) {
  const campaignsUIEvents = {
    openEditCampaignDialog: (id) => {
      history.push(`/campaigns/${id}/edit`);
    },
    openDeleteCampaignDialog: (id) => {
      history.push(`/campaigns/${id}/delete`);
    },
  };

  return (
    <CampaignsUIProvider campaignsUIEvents={campaignsUIEvents}>
      <CampaignsLoadingDialog />
      <Switch>
        <Route path="/campaigns/:id/edit">
          {({ history, match }) => <CreateCampaignCard />}
        </Route>
        <Route path="/campaigns/:id/delete">
          {({ history, match }) => (
            <CampaignDeleteDialog
              show={match != null}
              id={match && match.params.id}
              onHide={() => {
                history.push("/campaigns");
              }}
            />
          )}
        </Route>
        <CampaignsCard />
      </Switch>
    </CampaignsUIProvider>
  );
}

//  {/* <Route path="/campaigns/deleteCampaigns">
//           {({ history, match }) => (
//             <CampaignsDeleteDialog
//               show={match != null}
//               onHide={() => {
//                 history.push("/campaigns");
//               }}
//             />
//           )}
//         </Route> */}
// {/* <Route path="/campaigns/new">
//   {({ history, match }) => (
//     <CampaignEditDialog
//       show={match != null}
//       onHide={() => {
//         history.push("/campaigns");
//       }}
//     />
//   )}
// </Route>
// <Route path="/campaigns/:id/edit">
//   {({ history, match }) => (
//     <CampaignEditDialog
//       show={match != null}
//       id={match && match.params.id}
//       onHide={() => {
//         history.push("/campaigns");
//       }}
//     />
//   )}
// </Route>
// <Route path="/campaigns/deleteCampaigns">
//   {({ history, match }) => (
//     <CampaignsDeleteDialog
//       show={match != null}
//       onHide={() => {
//         history.push("/campaigns");
//       }}
//     />
//   )}
// </Route>
// <Route path="/campaigns/:id/delete">
//   {({ history, match }) => (
//     <CampaignDeleteDialog
//       show={match != null}
//       id={match && match.params.id}
//       onHide={() => {
//         history.push("/campaigns");
//       }}
//     />
//   )}
// </Route>
// <Route path="/campaigns/fetch">
//   {({ history, match }) => (
//     <CampaignsFetchDialog
//       show={match != null}
//       onHide={() => {
//         history.push("/campaigns");
//       }}
//     />
//   )}
// </Route> */}
