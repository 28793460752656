import React from "react";
import { Button } from "react-bootstrap";
import { Modal } from "react-bootstrap";
import { FaCheckCircle } from "react-icons/fa";
import { Link } from "react-router-dom";

export function PasswordChangeModal({ show, onHide }) {
   const handleClick = () => {
      onHide();
   };
   return (
      <>
         <Modal show={show} onHide={handleClick}>
            <Modal.Header closeButton></Modal.Header>
            <Modal.Body>
               <div className="d-flex">
                  <FaCheckCircle className="success-icon" />
                  <h5>Your Password Has been changed Successfully</h5>
               </div>
            </Modal.Body>
            <Modal.Footer>
               <Link to="/auth/login">
                  <Button variant="success" onClick={handleClick}>
                     Close
                  </Button>
               </Link>
            </Modal.Footer>
         </Modal>
      </>
   );
}
