import React, {  useMemo, useRef } from "react";
import { useDispatch } from "react-redux";
import { CampaignsGrouping } from "./campaigns-grouping/CampaignsGrouping";
import { CampaignsTable } from "./campaigns-table/CampaignsTable";
import { useCampaignsUIContext } from "./CampaignsUIContext";
import { Card, CardBody } from "../../../_metronic/_partials/controls";
import { CampaignsFilter } from "./campaigns-filter/CampaignsFilter";
import { Link } from "react-router-dom";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Snackbar,
} from "@material-ui/core";
import { withStyles } from "@material-ui/core/styles";
import * as campaignActions from "../../../_redux/campaigns/campaignsActions";

export function CampaignsCard() {
  const dispatch = useDispatch();
  const inputFile = useRef(null);
  const campaignsUIContext = useCampaignsUIContext();
  const campaignsUIProps = useMemo(() => {
    return {
      ids: campaignsUIContext.ids,
      newCampaignButtonClick: campaignsUIContext.newCampaignButtonClick,
    };
  }, [campaignsUIContext]);

  const DialogActions5 = withStyles((theme) => ({
    root: {
      margin: 0,
      padding: theme.spacing(1),
    },
  }))(DialogActions);

  const [openUpload, setOpenUpload] = React.useState(false);
  const [isLoading, setIsLoading] = React.useState(false);
  const [uploadedFile, setUploadedFile] = React.useState(null);
  const [open, setOpen] = React.useState(false);
  function handleClickOpenUpload() {
    setUploadedFile(null);
    setOpenUpload(true);
  }

  function handleCloseUpload() {
    if (!isLoading) {
      setOpenUpload(false);
    }
  }

  function handleFileSelect(event) {
    const file = event.target.files[0];
    setUploadedFile(file);
  }

  async function uploadFile() {
    if (uploadedFile) {
      setIsLoading(true);
      const formData = new FormData();
      formData.append("file", uploadedFile);
      await dispatch(campaignActions.uploadCampaign(formData));
      inputFile.current.value = "";
      setUploadedFile(null);
      setIsLoading(false);
      handleCloseUpload();
      setOpen(true);
    }
  }

  const handleClose = () => {
    setOpen(false);
  };
  return (
    <div>
      <Snackbar
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
        open={open}
        autoHideDuration={6000}
        onClose={handleClose}
        color="inherit"
        message="File uploaded successfully"
      />
      <div className="mt-4">
        <Link className="header-btn d-flex" to={"/dashboard"}>
          <p>{" < "} </p>
          <span>Back</span>
        </Link>
        <div className="d-flex justify-content-between">
          <p className="header-text">Existing Campaigns</p>
          <div>
            <a href="/template.csv">
              <button className="btn btn-secondary my-4 mr-2 btn-bold">
                Download Template
              </button>
            </a>

            <button
              className="btn btn-light-primary my-4 btn-bold"
              onClick={handleClickOpenUpload}
            >
              Upload
            </button>
          </div>
        </div>
      </div>
      <Card>
        <CardBody>
          <CampaignsFilter />
          {campaignsUIProps.ids}
          {campaignsUIProps.ids.length > 0 && <CampaignsGrouping />}
          <CampaignsTable />
        </CardBody>
      </Card>

      <Dialog
        onClose={handleCloseUpload}
        aria-labelledby="customized-dialog-title"
        maxWidth="sm"
        fullWidth={true}
        open={openUpload}
      >
        <DialogTitle sx={{ m: 0, p: 2 }} id="alert-dialog-title">
          Upload Bulk Campaigns
        </DialogTitle>
        <DialogContent>
          <div>
            <input
              type="file"
              ref={inputFile}
              accept=".csv, text/csv"
              onChange={handleFileSelect}
            />
          </div>
        </DialogContent>
        <DialogActions5>
          <Button
            onClick={uploadFile}
            disabled={isLoading}
            className="btn btn-light-primary my-4 btn-bold"
          >
            <div className="d-flex">
              {isLoading && (
                <span className="mr-7 spinner spinner-primary w-2"></span>
              )}
              {!isLoading && <span>Upload</span>}
            </div>
          </Button>
        </DialogActions5>
      </Dialog>
    </div>
  );
}
