// React bootstrap table next =>
// DOCS: https://react-bootstrap-table.github.io/react-bootstrap-table2/docs/
// STORYBOOK: https://react-bootstrap-table.github.io/react-bootstrap-table2/storybook/index.html
import React, { useEffect, useMemo } from "react";
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory, {
  PaginationProvider,
} from "react-bootstrap-table2-paginator";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import * as actions from "../../../../_redux/campaigns/campaignsActions";
import {
  getSelectRow,
  getHandlerTableChange,
  NoRecordsFoundMessage,
  PleaseWaitMessage,
  sortCaret,
  headerSortingClasses,
} from "../../../../_metronic/_helpers";
import * as uiHelpers from "../CampaignsUIHelpers";
import * as columnFormatters from "./column-formatters";
import { Pagination } from "../../../../_metronic/_partials/controls";
import { useCampaignsUIContext } from "../CampaignsUIContext";

export function CampaignsTable() {
  // Campaigns UI Context
  const campaignsUIContext = useCampaignsUIContext();
  const campaignsUIProps = useMemo(() => {
    return {
      ids: campaignsUIContext?.ids,
      setIds: campaignsUIContext?.setIds,
      queryParams: campaignsUIContext?.queryParams,
      setQueryParams: campaignsUIContext.setQueryParams,
      openEditCampaignDialog: campaignsUIContext.openEditCampaignDialog,
      openDeleteCampaignDialog: campaignsUIContext.openDeleteCampaignDialog,
    };
  }, [campaignsUIContext]);

  // Getting curret state of campaigns list from store (Redux)
  const { currentState } = useSelector(
    (state) => ({ currentState: state.campaigns }),
    shallowEqual
  );
  const { totalCount, entities, listLoading } = currentState;

  // Campaigns Redux state
  const dispatch = useDispatch();

  useEffect(() => {
    // clear selections list
    campaignsUIProps.setIds([]);
    // server call by queryParams
    dispatch(actions.filterCampaigns(campaignsUIProps.queryParams));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [campaignsUIProps.queryParams, dispatch]);

  // Table columns
  const columns = [
    {
      dataField: "ID",
      text: "ID",
      sort: true,
      sortCaret: sortCaret,
      headerSortingClasses,
    },
    {
      dataField: "campaignName",
      text: "Campaign Name",
      sort: true,
      sortCaret: sortCaret,
      headerSortingClasses,
    },
    {
      dataField: "industry.title",
      text: "Indsutry",
      sort: true,
      sortCaret: sortCaret,
      headerSortingClasses,
    },
    {
      dataField: "objective",
      text: "Objective",
      sort: true,
      sortCaret: sortCaret,
      headerSortingClasses,
    },
    {
      dataField: "action",
      text: "Actions",
      formatter: columnFormatters.ActionsColumnFormatter,
      formatExtraData: {
        openEditCampaignDialog: campaignsUIProps.openEditCampaignDialog,
        openDeleteCampaignDialog: campaignsUIProps.openDeleteCampaignDialog,
      },
      classes: "text-right pr-0",
      headerClasses: "text-right pr-3",
      style: {
        minWidth: "100px",
      },
    },
  ];
  // Table pagination properties
  const paginationOptions = {
    custom: true,
    totalSize: totalCount,
    sizePerPageList: uiHelpers.sizePerPageList,
    sizePerPage: campaignsUIProps.queryParams.pageSize,
    page: campaignsUIProps.queryParams.pageNumber,
  };
  return (
    <>
      <PaginationProvider pagination={paginationFactory(paginationOptions)}>
        {({ paginationProps, paginationTableProps }) => {
          return (
            <Pagination
              isLoading={listLoading}
              paginationProps={paginationProps}
            >
              <BootstrapTable
                wrapperClasses="table-responsive"
                bordered={false}
                classes="table table-head-custom table-vertical-center overflow-hidden"
                bootstrap4
                remote
                keyField="id"
                data={
                  entities === null || entities === undefined ? [] : entities
                }
                columns={columns}
                defaultSorted={uiHelpers.defaultSorted}
                onTableChange={getHandlerTableChange(
                  campaignsUIProps.setQueryParams
                )}
                selectRow={getSelectRow({
                  entities,
                  ids: campaignsUIProps.ids,
                  setIds: campaignsUIProps.setIds,
                })}
                {...paginationTableProps}
              >
                <PleaseWaitMessage
                  entities={
                    entities === null || entities === undefined ? [] : entities
                  }
                />
                <NoRecordsFoundMessage
                  entities={
                    entities === null || entities === undefined ? [] : entities
                  }
                />
              </BootstrapTable>
            </Pagination>
          );
        }}
      </PaginationProvider>
    </>
  );
}
