import React from "react";
import ReactApexChart from "react-apexcharts";

const UsersBreakdown = () => {
  let data = {
    series: [86, 27, 15, 19],
    options: {
      chart: {
        width: 380,
        type: "pie",
      },
      labels: ["USA", "Russia", "Pakistan", "India"],
      responsive: [
        {
          breakpoint: 350,
          options: {
            chart: {
              width: 200,
            },
            legend: {
              position: "bottom",
            },
          },
        },
      ],
    },
  };
  return (
    <>
      <div id="chart">
        <ReactApexChart
          options={data.options}
          series={data.series}
          type="pie"
          width={380}
        />
      </div>
    </>
  );
};

export default UsersBreakdown;
