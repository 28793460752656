import React from "react";
import "react-circular-progressbar/dist/styles.css";
import { ReactAreaChart } from "./Components/areaChart";
import { ReactBarChart } from "./Components/barChart";
import { ReactDashedChart } from "./Components/dashedChart";

export function UserHistory({ className, usersArray }) {
  let Users = usersArray?.users[0];
  return (
    <div className="row">
      <div className="col-xl-4 col-md-12">
        <div className={`card card-custom dashboardCard`}>
          <div className="card-body">
            <div className="rate-desc ml-4">Free version Users</div>
          <span className="rate ml-4">{Users?.freeUsers ? Users?.freeUsers : "0" }</span>
          </div>
          <ReactAreaChart />
        </div>
      </div>

      <div className="col-xl-4 col-md-6">
        <div className={`card card-custom dashboardCard`}>
          <div className="card-body">
            <div className="rate-desc ml-4">Paid version Users</div>
          <span className="rate ml-4">{Users?.paidUsers ? Users?.paidUsers: "0"}</span>
          </div>
          <ReactDashedChart />
        </div>
      </div>

      <div className="col-xl-4 col-md-6">
        <div className={`card card-custom dashboardCard`}>
          <div className="card-body">
          <div className="rate-desc ml-4">User signed up</div>
            <span className="rate ml-4">{Users?.totalUsers? Users?.totalUsers : "0"}</span>
          </div>
          <ReactBarChart />
        </div>
        </div>
      </div>
  );
}


