import React, { useState } from "react";
import { CreateCampaign } from "../../../app/pages/campaigns/Components/Pages/CreateCampaign";
import { Header } from "../../../app/pages/campaigns/Layout/Header";
import { CampaignSider } from "../../../app/pages/campaigns/Layout/Sider";
import { shallowEqual, useSelector } from "react-redux";

export function CreateCampaignCard() {
  const [step, setStep] = useState(0);
  const [campaignName, setCampaignName] = useState("");

  const { campaignData, campaignCreate } = useSelector(
    (state) => ({
      campaignData: state.campaigns.CampaignData,
      campaignCreate: state.campaigns.campaignCreate,
    }),
    shallowEqual
  );

  return (
    <>
      <div className="createMain">
        <Header value={campaignName} setCampaignName={setCampaignName} />
        <div className="row pt-5">
          <div className="col-lg-1"></div>
          <div className="col-lg-3">
            <CampaignSider stepState={{ step, setStep }} />
          </div>
          <div className="col-lg-6 ">
            <CreateCampaign
              step={step}
              setStep={setStep}
              campaignName={campaignName}
              createCampaign={campaignCreate}
              setCampaignName={setCampaignName}
              campaignDataObject={campaignData}
            />
          </div>
        </div>
      </div>
    </>
  );
}
