import React, { useEffect } from "react";
import { Link } from "react-router-dom";
import { BreakdownByCountry } from "../../campaignAdmin/Breakdowns/breakdownCountry";
import { BreakdownByIndustry } from "../../campaignAdmin/Breakdowns/breakdownIndustry";
import { TotalTransaction } from "../../campaignAdmin/totalTransactions";
import { UserHistory } from "../../campaignAdmin/userHistory";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import * as dashboardActions from "../../../_redux/dashboard/dashboardActions";
import { ReactAreaChart } from "../../campaignAdmin/Components/areaChart";
import { BreakdownByObject } from "../../campaignAdmin/Breakdowns/breakdownObjectives";

export function Demo1Dashboard() {
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(dashboardActions.getDashboardData());
    dispatch(dashboardActions.getTransactionsData());
    // eslint-disable-next-line
  }, []);

  const { transactionData, dashBoardData } = useSelector(
    (state) => ({
      dashBoardData: state.dashboard.dashBoardData,
      transactionData: state.dashboard.transactionData,
    }),
    shallowEqual
  );

  return (
    <>
      <hr />
      <div className="main-header mb-4">
        <div className="row">
          <div className="col-sm-6">
            <h2 className="p-2 mb-4">Dashboard</h2>
          </div>

          <div className="col-sm-3">
            <Link to={"/campaigns"}>
              <button className="Btn1 ">View Existing Campaigns</button>
            </Link>
          </div>

          <div className="col-sm-3">
            <Link to={"/campaign-create"}>
              <button className="Btn2">Create a New Campaign</button>
            </Link>
          </div>
        </div>
      </div>

      <div className="row">
        <div className="col-xl-12 mb-3">
          <UserHistory
            className="card-stretch gutter-b"
            usersArray={dashBoardData}
          />
        </div>
      </div>
      <div className="row mb-10 mt-4">
        <div className="col-lg-6 col-md-6">
          {/* <Newsletter className={"card-stretch gutter-b"} /> */}
          <BreakdownByIndustry
            className={"card-stretch"}
            industries={dashBoardData?.industries || []}
          />
        </div>
        <div className="col-lg-6 col-md-6">
          <BreakdownByObject
            className={"card-stretch"}
            objectives={dashBoardData?.objectives || []}
          />
        </div>
      </div>
      <div className="row mb-5">
        <div className="col-lg-6">
          <div className={`card card-custom h-100`}>
            <div className="card-body">
              <div className="rate-desc ml-4">Total Transcations</div>
              <div className="d-flex">
                <span className="rate ml-4 mt-4">
                  {dashBoardData?.transactions
                    ? dashBoardData?.transactions
                    : "0"}
                </span>
                <div className="ml-auto">
                  <div className="d-flex mt-4">
                    <div className="rate-desc mr-8 ">Daily Goal</div>
                    <div className="rate-desc ml-auto mr-6">50%</div>
                  </div>

                  <div className="d-flex mt-4">
                    <div className=" rate-desc mr-8">This Week</div>
                    <div className="rate-desc ml-auto mr-6">130</div>
                  </div>
                </div>
              </div>
            </div>
            <ReactAreaChart />
          </div>
        </div>
        <div className="col-lg-6">
          <BreakdownByCountry className={"card-stretch gutter-b"} />
        </div>
      </div>

      <div className="row">
        <div className="col-12">
          <TotalTransaction
            className={"card-stretch gutter-b mt-5"}
            TotalTransaction={transactionData}
          />
        </div>
      </div>
    </>
  );
}
