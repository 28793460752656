import React, { lazy, Suspense } from "react";
import { Redirect, Route, Switch } from "react-router-dom";
import { LayoutSplashScreen, Layout, ContentRoute } from "../_metronic/layout";
import { DashboardPage } from "./pages/DashboardPage";
import { CampaignsPage } from "./pages/campaigns/CampaignsPage";
import { TransactionsPage } from "./pages/TransactionsPage";
import { ContactsPage } from "./pages/ContactsPage";
import { UsersPage } from "./pages/UsersPage";
import { BuilderPage } from "./pages/BuilderPage";
import { CreateCampaignCard } from "../_metronic/campaignAdmin/CreateCampaign/createCampaignCard";
const ReactBootstrapPage = lazy(() =>
  import("./modules/ReactBootstrapExamples/ReactBootstrapPage")
);

// import { MyPage } from "./pages/MyPage";

// const GoogleMaterialPage = lazy(() =>
//   import("./modules/GoogleMaterialExamples/GoogleMaterialPage")
// );
// const ReactBootstrapPage = lazy(() =>
//   import("./modules/ReactBootstrapExamples/ReactBootstrapPage")
// );
// const ECommercePage = lazy(() =>
//   import("./modules/ECommerce/pages/eCommercePage")
// );

export default function BasePage() {
  // useEffect(() => {
  // }, []) // [] - is required if you need only one call
  // https://reactjs.org/docs/hooks-reference.html#useeffect

  return (
    <Suspense fallback={<LayoutSplashScreen />}>
      <Switch>
        {<Redirect exact from="/" to="/dashboard" />}
        <ContentRoute path="/campaign-create" component={CreateCampaignCard} />
        <ContentRoute path="/builder" component={BuilderPage} />
        <Route path="/react-bootstrap" component={ReactBootstrapPage} />

        <Layout>
          <ContentRoute path="/dashboard" component={DashboardPage} />
          <ContentRoute path="/campaigns" component={CampaignsPage} />
          <ContentRoute path="/transactions" component={TransactionsPage} />
          <ContentRoute path="/users" component={UsersPage} />
          <ContentRoute path="/contacts" component={ContactsPage} />
        </Layout>

        <Redirect to="error/error-v1" />
      </Switch>
    </Suspense>
  );
}
