import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { Card, CardBody } from "../../_metronic/_partials/controls";
import { Formik } from "formik";
import paginationFactory, {
  PaginationProvider,
} from "react-bootstrap-table2-paginator";
import {
  sortCaret,
  headerSortingClasses,
  PleaseWaitMessage,
  NoRecordsFoundMessage,
} from "../../_metronic/_helpers";
import { Pagination } from "../../_metronic/_partials/controls";
import BootstrapTable from "react-bootstrap-table-next";
import axios from "axios";

export function UsersPage() {
  const [entities, setEntities] = useState([]);
  const [totalSize, setTotalSize] = useState(0);
  const [search, setSearch] = useState("");
  const [sortField, setSortField] = useState("");
  const [sortOrder, setSortOrder] = useState("");
  const [page, setPage] = useState(1);
  const [sizePerPage, setSizePerPage] = useState(10);
  const [isLoading, setIsLoading] = useState(false);
  const custom = true;
  const sizePerPageList = [
    { text: "3", value: 3 },
    { text: "5", value: 5 },
    { text: "10", value: 10 },
  ];

  const loadData = async (obj) => {
    setIsLoading(true);
    await axios
      .get(
        `/users?limit=${obj.limit}&sortBy=${obj.sortField}:${
          obj.sortOrder
        }&page=${obj.page}${obj.name ? "&name=" + obj.name : ""}`
      )
      .then((response) => {
        const { results, totalResults } = response.data;
        setEntities(results);
        setTotalSize(totalResults);
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  useEffect(() => {
    loadData({
      page,
      limit: sizePerPage,
      name: "",
      sortOrder: "desc",
      sortField: "createdAt",
    });
  }, [page, sizePerPage]);

  const applyFilter = async (value) => {
    setSearch(value);
    if (value.length === 0) {
      setPage(1);
      loadData({
        page: 1,
        limit: sizePerPage,
        name: value,
        sortOrder: "desc",
        sortField: "createdAt",
      });
    } else {
      setPage(1);
      loadData({
        page: 1,
        limit: sizePerPage,
        name: value,
        sortOrder: "desc",
        sortField: "createdAt",
      });
    }
  };

  const onTableChange = async (type, newState) => {
    let sortOrderNew = "desc";
    let sortFieldNew = "createdAt";

    if (sortOrder && sortField) {
      sortOrderNew = sortOrder;
      sortFieldNew = sortField;
    }

    if (type === "sort") {
      setSortOrder(newState.sortOrder);
      setSortField(newState.sortField);
      sortOrderNew = newState.sortOrder;
      sortFieldNew = newState.sortField;
    }

    if (type !== "sort") {
      if (page === 1 && newState.page === 1) {
        return;
      }
    }

    if (newState.page === 0) {
      return;
    }
    const pageNumber = newState.page || 1;
    const sizePerPageNumber = newState.sizePerPage || 10;
    setPage(pageNumber);
    setSizePerPage(sizePerPageNumber);
    await loadData({
      page: pageNumber,
      limit: sizePerPageNumber,
      name: search,
      sortOrder: sortOrderNew,
      sortField: sortFieldNew,
    });
  };

  const columns = [
    {
      dataField: "name",
      text: "Name",
      sort: true,
      sortCaret: sortCaret,
      headerSortingClasses,
    },
    {
      dataField: "email",
      text: "Email",
      sort: true,
      sortCaret: sortCaret,
      headerSortingClasses,
    },
    {
      dataField: "role",
      text: "Role",
      sort: true,
      sortCaret: sortCaret,
      headerSortingClasses,
    },
    {
      dataField: "paidUser",
      text: "Is Paid",
      sort: true,
      sortCaret: sortCaret,
      headerSortingClasses,
    },
    {
      dataField: "createdAt",
      text: "Created AT",
      sort: true,
      sortCaret: sortCaret,
      headerSortingClasses,
    },
  ];
  return (
    <div>
      <div className="mt-4">
        <Link className="header-btn d-flex" to={"/dashboard"}>
          <p>{" < "} </p>
          <span>Back</span>
        </Link>
        <div className="d-flex justify-content-between">
          <p className="header-text">All Users ({totalSize})</p>
        </div>
      </div>
      <Card>
        <CardBody>
          <Formik
            initialValues={{
              searchText: "",
            }}
            onSubmit={(values) => {
              applyFilter(values.searchText);
            }}
          >
            {({ handleSubmit, handleBlur }) => (
              <form onSubmit={handleSubmit} className="form form-label-right">
                <div className="form-group row">
                  <div className="col-lg-2">
                    <input
                      type="text"
                      className="form-control"
                      name="searchText"
                      placeholder="Search By Name"
                      onChange={(e) => {
                        applyFilter(e.target.value);
                      }}
                    />
                  </div>
                </div>
              </form>
            )}
          </Formik>
          <PaginationProvider
            pagination={paginationFactory({
              custom,
              totalSize,
              page,
              sizePerPageList,
              sizePerPage,
            })}
          >
            {({ paginationProps, paginationTableProps }) => {
              return (
                <Pagination
                  isLoading={isLoading}
                  paginationProps={paginationProps}
                >
                  <BootstrapTable
                    wrapperClasses="table-responsive"
                    bordered={false}
                    classes="table table-head-custom table-vertical-center overflow-hidden"
                    bootstrap4
                    remote
                    keyField="id"
                    data={
                      entities === null || entities === undefined
                        ? []
                        : entities
                    }
                    onTableChange={onTableChange}
                    {...paginationTableProps}
                    columns={columns}
                  >
                    <PleaseWaitMessage
                      entities={
                        entities === null || entities === undefined
                          ? []
                          : entities
                      }
                    />
                    <NoRecordsFoundMessage
                      entities={
                        entities === null || entities === undefined
                          ? []
                          : entities
                      }
                    />
                  </BootstrapTable>
                </Pagination>
              );
            }}
          </PaginationProvider>
        </CardBody>
      </Card>
    </div>
  );
}
