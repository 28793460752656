import React from "react";
import { Modal } from "react-bootstrap";
import { Link } from "react-router-dom";
import { FaCheckCircle } from "react-icons/fa";
import { useHistory } from "react-router-dom";
export function SuccessModal(props) {
  let history = useHistory();

  function onClick() {
    history.push("/dashboard");
  }

  return (
    <Modal
      {...props}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <div className="text-center ">
        <FaCheckCircle className="svg" />
      </div>
      <Modal.Body>
        <div className="text-center modalText text mb-5">
          Your campaign has been launched successfully!{" "}
        </div>
        <Link to={"/dashboard"}>
          <div className="text-center">
            <button onClick={onClick} className="successBtn">
              Go To Dashboard
            </button>
          </div>
        </Link>
      </Modal.Body>
    </Modal>
  );
}
