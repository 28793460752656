import React from "react";
import { AreaChart, Area, ResponsiveContainer } from "recharts";

const data = [
  {
    name: "Page A",
    uv: 600,
  },
  {
    name: "Page B",
    uv: 400,
  },
  {
    name: "Page C",
    uv: 600,
  },
  {
    name: "Page D",
    uv: 400,
  },
  {
    name: "Page E",
    uv: 500,
  },
  {
    name: "Page F",
    uv: 200,
  },
  {
    name: "Page G",
    uv: 700,
  },
];
export function ReactDashedChart() {
  return (
    <ResponsiveContainer width="100%" height="60%">
      <AreaChart
        width={200}
        height={60}
        data={data}
        margin={{
          top: 5,
          right: 0,
          left: 0,
          bottom: 5,
        }}
      >
        <Area
          type="monotone"
          dataKey="uv"
          stroke="#8C87A6"
          fill="#FFFFFF"
          strokeDasharray="5 5"
        />
      </AreaChart>
    </ResponsiveContainer>
  );
}
