import React from "react";
import { Modal } from "react-bootstrap";
import * as CampaignActions from "../../../../../_redux/campaigns/campaignsActions";
import { useDispatch } from "react-redux";

export function AddIndustry({
  show,
  onHide,
  addIndustry,
  setAddIndustry,
  industry,
}) {
  const dispatch = useDispatch();

  const onSubmit = () => {
    dispatch(CampaignActions.createIndustry(industry));
    onHide();
  };

  const onChange = (e) => {
    e.persist();
    setAddIndustry(e.target.value);
  };

  return (
    <>
      <Modal
        show={show}
        onHide={onHide}
        aria-labelledby="example-modal-sizes-title-lg"
      >
        <Modal.Header closeButton>
          <Modal.Title id="example-modal-sizes-title-lg">
            Create New Industry
          </Modal.Title>
        </Modal.Header>

        <Modal.Body>
          <p>Add New Industry</p>
          <input
            className="w-50 industryAdd"
            type="text"
            name="title"
            defaultValue ={addIndustry}
            onChange={onChange}
            autoComplete="off"
          />
        </Modal.Body>

        <Modal.Footer>
          <div>
            <button
              type="button"
              onClick={onHide}
              className="btn btn-light btn-elevate mr-3"
            >
              Cancel
            </button>
            <button type="button" onClick={onSubmit} className="btn btn-success">
              Add
            </button>
          </div>
        </Modal.Footer>
      </Modal>
    </>
  );
}
