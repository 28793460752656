import React from "react";
import { TO_CAPITALIZED_WORDS } from "../../../../../../_metronic/_helpers/helperFunctions";

const CampaignMetrics = ({ campaignMatrix, setCampaignMatrix }) => {
  return (
    <div>
      <div className="metrics">
        <h4 className="text mb-5">Search engines</h4>

        {Object.keys(campaignMatrix)?.map((item, index) => {
          return (
            <>
              <div key={index} className="heading mb-4 mt-7">
                <h4>
                  <b>{TO_CAPITALIZED_WORDS(item)}</b>
                </h4>
              </div>
              {Object.keys(campaignMatrix[item])?.map((subItem, index) => {
                return (
                  <>
                    <div key={index} className="inner-heading mt-7 mb-6">
                      <h6>{TO_CAPITALIZED_WORDS(subItem)}</h6>
                    </div>
                    <div className=" mb-10">
                      <div className="row">
                        {Object.keys(campaignMatrix[item][subItem])?.map(
                          (childsubItem, index) => {
                            return (
                              <div className="col-lg-4 col-md-4 col-sm-6 col-12">
                                <div key={index} className="mt-2">
                                  <p>{TO_CAPITALIZED_WORDS(childsubItem)}</p>

                                  <input
                                    type="number"
                                    name={childsubItem}
                                    value={
                                      campaignMatrix[`${item}`][`${subItem}`][
                                        `${childsubItem}`
                                      ] > 0
                                        ? campaignMatrix[`${item}`][
                                        `${subItem}`
                                        ][`${childsubItem}`]
                                        : ""
                                    }
                                    placeholder={
                                      campaignMatrix[`${item}`][`${subItem}`][
                                        `${childsubItem}`
                                      ] === 0
                                        ? "0"
                                        : ""
                                    }
                                    onChange={(e) => {
                                      setCampaignMatrix({
                                        ...campaignMatrix,
                                        [item]: {
                                          ...campaignMatrix[item],
                                          [subItem]: {
                                            ...campaignMatrix[item][subItem],

                                            ...campaignMatrix[item][subItem][
                                            childsubItem
                                            ],
                                            [childsubItem]: Number(
                                              e.target.value
                                            ),
                                          },
                                        },
                                      });
                                    }}
                                  />
                                </div>
                              </div>
                            );
                          }
                        )}
                      </div>
                    </div>
                  </>
                );
              })}
            </>
          );
        })}
      </div>
    </div>
  );
};

export default CampaignMetrics;
