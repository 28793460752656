import React from "react";
import { Modal } from "react-bootstrap";
import { ImCross } from "react-icons/im";

export function ErrorModal({ show, campaignError, onHide }) {
  return (
    <Modal
      show={show}
      onHide={onHide}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <div className="text-center ">
        <ImCross className="svg1" />
      </div>
      <Modal.Body>
      <h4 className="text-center mb-3">
            Error, You Can't Create a Campaign
          </h4>
        <div className="alert alert-danger" role="alert">
          <div className="error-msg">{campaignError}</div>
        </div>
      </Modal.Body>
      <Modal.Footer>
        <div>
          <button
            type="button"
            onClick={onHide}
            className="btn btn-light btn-elevate mr-3"
          >
            Cancel
          </button>
        </div>
      </Modal.Footer>
    </Modal>
  );
}
