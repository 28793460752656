export const CAPITALIZE = (word) => {
  return word.charAt(0).toUpperCase() + word.substring(1);
};

export const TO_CAPITALIZED_WORDS = (word) => {
  var words = word.match(/[A-Za-z][a-z]*/g) || [];

  return words.map(CAPITALIZE).join(" ");
};

export function NUMBERS_WITH_COMMAS(x) {
  return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
}

export const ADD_ZEROES = (num) => {
  let str = "" + num;
  let pad = "0000";
  let number = pad.substring(0, pad.length - str.length) + str;
  return number;
};
