import React from "react";
import { Button } from "react-bootstrap";
import { Modal } from "react-bootstrap";
import { FaCheckCircle } from "react-icons/fa";
import { useHistory } from "react-router-dom";

export function EmailSentModal({ show, onHide }) {
  let history = useHistory();
  const handleClick = () => {
    history.push("/auth/forgot-password");
  };
  return (
    <>
      <Modal show={show} onHide={handleClick}>
        <Modal.Header closeButton></Modal.Header>
        <Modal.Body>
          <div className="d-flex">
            <FaCheckCircle className="success-icon" />
            <h5>Reset Password link has been sent to your given mail</h5>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="success" onClick={handleClick}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
}
