import React from "react";
import { useParams } from "react-router-dom";

export function CampaignSider(props) {
  const { id } = useParams();

  return (
    <>
      <div className="mb-10 Sider">
        {id === undefined ? <h2>Create Campaign</h2> : <h2>Edit Campaign</h2>}
        {id === undefined ? (
          <p>
            First, we need to know a little bit more about your organization
          </p>
        ) : (
          <p>view or edit your campaign here</p>
        )}

        <ul className="mb-6">
          <li className={`${props.stepState.step === 0 ? "ulColor" : ""}`}>
            CAMPAIGN OBJECTIVES
          </li>

          <li className={`${props.stepState.step === 1 ? "ulColor" : ""}`}>
            BUDGET BREAKDOWN
          </li>

          <li className={`${props.stepState.step === 2 ? "ulColor" : ""}`}>
            CAMPAIGN METRICS
          </li>

          <li className={`${props.stepState.step === 3 ? "ulColor" : ""}`}>
            OVERVIEW
          </li>
        </ul>

        {/* <div className="info-card">
          <div className="card border-0   ">
            <div className="card-body">
              <h5>Instruction</h5>
              <p className="text-justify">
                We recommend having at least 5 objectives so we can have a good
                amount of audience to target.
              </p>
            </div>
          </div>
        </div> */}
      </div>
    </>
  );
}
