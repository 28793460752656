import axios from "axios";
import { Storage } from "react-jhipster";
import { CE_AUTH } from "../_metronic/_helpers/constant";

const TIMEOUT = 1 * 60 * 1000;
axios.defaults.timeout = TIMEOUT;
axios.defaults.baseURL = process.env.REACT_APP_SERVER_URL;
const setupAxios = (onUnauthenticated) => {
  const onRequestSuccess = (config) => {
    const token = Storage.local.get(CE_AUTH) || Storage.session.get(CE_AUTH);
    if (token) {
      config.headers.Authorization = `Bearer ${token}`;
    }
    return config;
  };
  const onResponseSuccess = (response) => response;
  const onResponseError = (err) => {
    const status = err.status || (err.response ? err.response.status : 0);
    if (status === 403 || status === 401 || status === 0) {
      window.location = "/logout";
      // onUnauthenticated();
    }
    return Promise.reject(err);
  };
  axios.interceptors.request.use(onRequestSuccess);
  axios.interceptors.response.use(onResponseSuccess, onResponseError);
};
export default setupAxios;
