import React from "react";
import UsersBreakdown from "../PieChart";

export function BreakdownByCountry({ className }) {
  return (
    <>
      <div className={`card card-custom ${className}`}>
        <div className="card-body pb-3">
          <div className="pieChart">
            <h1>Users breakdown by Country</h1>
          </div>
          <UsersBreakdown />
        </div>
      </div>
    </>
  );
}
