import React from "react";
import { NUMBERS_WITH_COMMAS } from "../../../../../_metronic/_helpers/helperFunctions";
import { BudgetSplit } from "./BudgetSplit";
import MetricesOverview from "./Metrics/metricesOverview";

export function Overview({
  firstPageData,
  camapignData,
  childArray,
  setCamapignData,
  campaginKeysArray,
  campaignMatrix,
  setCampaignMatrix,
}) {
  return (
    <div>
      <div className="pageText mb-5">Campaign Objective</div>
      <div className="overview">
        <div className="card border-0 mb-5">
          <div className="card-body">
            <div className="row">
              <div className="col-6">
                <p className="desg">Select Industry</p>
              </div>
              <div className="col-6">
                <p className="detail">{firstPageData?.industry?.label}</p>
              </div>
            </div>

            <div className="row">
              <div className="col-6">
                <p className="desg">Budget Bracket</p>
              </div>
              <div className="col-6 d-flex ">
                <p className="detail mr-1">
                  ${NUMBERS_WITH_COMMAS(firstPageData.budgetBracket?.min)}−
                </p>
                <p className="detail">
                  ${NUMBERS_WITH_COMMAS(firstPageData.budgetBracket?.max)}
                </p>
              </div>
            </div>

            <div className="row">
              <div className="col-6">
                <p className="desg">Objective</p>
              </div>
              <div className="col-6">
                <p className="detail">{firstPageData?.objective?.label}</p>
              </div>
            </div>
            <div className="row">
              <div className="col-6">
                <p className="desg">Country</p>
              </div>
              <div className="col-6">
                <p className="detail">{firstPageData?.country}</p>
              </div>
            </div>

            <div className="row">
              <div className="col-6">
                <p className="desg">Industry Age</p>
              </div>
              <div className="col-6">
                <p className="detail">{firstPageData.industryAge}</p>
              </div>
            </div>

            <div className="row">
              <div className="col-6">
                <p className="desg">Experience in the industry</p>
              </div>
              <div className="col-6">
                <p className="detail">{firstPageData.userExperience}</p>
              </div>
            </div>

            {/* <div className="row">
              <div className="col-6">
                <p className="desg">CPC</p>
              </div>
              <div className="col-6">
                <p className="detail">{firstPageData.campaignMatrix?.cpc}%</p>
              </div>
            </div>

            <div className="row">
              <div className="col-6">
                <p className="desg">CTR</p>
              </div>
              <div className="col-6">
                <p className="detail">{firstPageData.campaignMatrix?.ctr}%</p>
              </div>
            </div>
            <div className="row">
              <div className="col-6">
                <p className="desg">CR</p>
              </div>
              <div className="col-6">
                <p className="detail">{firstPageData.campaignMatrix?.cr}%</p>
              </div>
            </div>
            <div className="row">
              <div className="col-6">
                <p className="desg">CPA</p>
              </div>
              <div className="col-6">
                <p className="detail">{firstPageData.campaignMatrix?.cpa}</p>
              </div>
            </div>
           
            <div className="row">
              <div className="col-6">
                <p className="desg">CPL</p>
              </div>
              <div className="col-6">
                <p className="detail">{firstPageData.campaignMatrix?.cpl}</p>
              </div>
            </div> */}
          </div>
        </div>

        <div className="heading-text mb-4 mt-4">
          Enter budget split breakdown for the the following
        </div>

        <div>
          {campaginKeysArray.map((item, index) => {
            return (
              <div key={index}>
                <BudgetSplit
                  heading={item}
                  key={index}
                  childArray={childArray[index]}
                  setCamapignData={setCamapignData}
                  camapignData={camapignData}
                />
              </div>
            );
          })}

          <MetricesOverview
            campaignMatrix={campaignMatrix}
            setCampaignMatrix={setCampaignMatrix}
          />
        </div>
      </div>
    </div>
  );
}
