import { all } from "redux-saga/effects";
import { combineReducers } from "redux";
import {campaignsSlice} from "../_redux/campaigns/campaignsSlice"
import  {dashboardSlice} from "../_redux/dashboard/dashboardSlice"
import * as auth from "../app/modules/Auth/_redux/authRedux";
// import {productsSlice} from "../app/modules/ECommerce/_redux/products/productsSlice";
// import {remarksSlice} from "../app/modules/ECommerce/_redux/remarks/remarksSlice";
// import {specificationsSlice} from "../app/modules/ECommerce/_redux/specifications/specificationsSlice";

export const rootReducer = combineReducers({
  auth: auth.reducer,
  dashboard:dashboardSlice.reducer,
  campaigns: campaignsSlice.reducer
});

export function* rootSaga() {
  yield all([auth.saga()]);
}
