import axios from "axios";

export const LOGIN_URL = "/auth/adminLogin";
export const REGISTER_URL = "/auth/register";
export const REQUEST_PASSWORD_URL = "/auth/forgot-password";
export const CHANGE_PASSWORD = "/auth/change-password";
export const RESET_PASSWORD_URL = "/auth/reset-password";

export const ME_URL = "/auth/who-am-i";

export function login(email, password) {
  return axios.post(LOGIN_URL, { email, password });
}

export function changePassword(oldPassword, newPassword) {
  return axios.patch(CHANGE_PASSWORD, { oldPassword, newPassword });
}

export function register(email, fullname, username, password) {
  return axios.post(REGISTER_URL, { email, fullname, username, password });
}

export function requestPassword(email) {
  return axios.post(REQUEST_PASSWORD_URL, { email });
}
export function resetPassword(token, password) {
  return axios.post(`${RESET_PASSWORD_URL}?token${token}`, { password });
}

export function getUserByToken() {
  axios.get(ME_URL).then((res) => {});
  // Authorization head should be fulfilled in interceptor.
  return axios.get(ME_URL);
}
