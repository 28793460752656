import * as requestFromServer from "./dashboardCrud";

import { callTypes, dashboardSlice } from "./dashboardSlice";

const { actions } = dashboardSlice;

export const getDashboardData = () =>  (dispatch) => {
   dispatch(actions.startCall({ callType: callTypes.action }));
   return requestFromServer
      .getDashboard()
      .then((response) => {
         dispatch(
            actions.dashboardGet({
              dashBoard: response.data,
            })
          );
      })
      .catch((error) => {
         error.clientMessage = "not running";
         dispatch(actions.catchError({ error, callType: callTypes.action }));
      });
};


export const getTransactionsData = () =>  (dispatch) => {
   dispatch(actions.startCall({ callType: callTypes.action }));
   return requestFromServer
      .getTransactions()
      .then((response) => {
         dispatch(
            actions.TransactionGet({
               transaction: response.data,
            })
          );
      })
      .catch((error) => {
         error.clientMessage = "not running";
         dispatch(actions.catchError({ error, callType: callTypes.action }));
      });
};
