import React from "react";
import { blockInvalidChar } from "../../../../../src/_metronic/_helpers/constant";
import { TO_CAPITALIZED_WORDS } from "../../../../_metronic/_helpers/helperFunctions";

export function InputSplit({
  heading,
  childArray,
  setCamapignData,
  camapignData,
}) {
  return (
    <>
      <div className="input-fields mb-5">
        <div
          className="alert alert-danger"
          role="alert"
          style={{
            display: `${
              Object.values(camapignData[`${heading}`]).reduce(
                (a, b) => a + b
              ) <= 100
                ? "none"
                : "block"
            }`,
          }}
        >
          Your
          <b> {TO_CAPITALIZED_WORDS(heading)} </b> takes more then 100%
        </div>

        <div className="pageText mb-8">{TO_CAPITALIZED_WORDS(heading)}</div>
        <div className="row mt-5">
          {childArray?.length
            ? childArray.map((subITem, index) => {
                return (
                  <div key={index} className="col-lg-6 col-md-4 col-sm-6">
                    <div className="d-flex mb-4 ">
                      <div className="input-inner-text mt-3 ">
                        {TO_CAPITALIZED_WORDS(subITem)}
                      </div>
                      <div className="ml-auto">
                        <input
                          className="percentIcon"
                          name={subITem}
                          id={subITem}
                          value={
                            camapignData[`${heading}`][`${subITem}`] > 0
                              ? camapignData[`${heading}`][`${subITem}`]
                              : ""
                          }
                          placeholder={
                            camapignData[`${heading}`][`${subITem}`] === 0
                              ? "0"
                              : ""
                          }
                          type="number"
                          onKeyDown={blockInvalidChar}
                          onChange={(e) => {
                            e.preventDefault();
                            setCamapignData({
                              ...camapignData,
                              [`${heading}`]: {
                                ...camapignData[`${heading}`],
                                [`${subITem}`]: Number(e.target.value),
                              },
                            });
                          }}
                        />
                      </div>
                    </div>
                  </div>
                );
              })
            : null}
        </div>
      </div>
    </>
  );
}
