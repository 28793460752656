import React from "react";
import { TO_CAPITALIZED_WORDS } from "../../../../../_metronic/_helpers/helperFunctions";

export const BudgetSplit = ({
  heading,
  childArray,
  setCamapignData,
  camapignData,
}) => {
  return (
    <div>
      <div className="card border-0 mt-4 mb-4">
        <div className="card-body">
          <div className="input-fields mb-5">
            <div className="pageText mb-8">{TO_CAPITALIZED_WORDS(heading)}</div>
            <div className="row mt-5">
              {childArray?.length
                ? childArray.map((subITem, index) => {
                    return (
                      <div key={index} className="col-lg-5">
                        <div className="d-flex mb-4">
                          <div className="input-inner-text">
                            {TO_CAPITALIZED_WORDS(subITem)}
                          </div>
                          <div className="ml-auto">
                            {camapignData[`${heading}`][`${subITem}`] > 0
                              ? camapignData[`${heading}`][`${subITem}`]
                              : ""}

                            {camapignData[`${heading}`][`${subITem}`] === 0
                              ? "0%"
                              : "%"}
                          </div>
                        </div>
                      </div>
                    );
                  })
                : null}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
