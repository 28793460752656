import React from "react";
import { AreaChart, Area, ResponsiveContainer } from "recharts";

const data = [
  {
    name: "Page A",
    uv: 600,
  },
  {
    name: "Page B",
    uv: 400,
  },
  {
    name: "Page C",
    uv: 700,
  },
  {
    name: "Page D",
    uv: 450,
  },
  {
    name: "Page E",
    uv: 600,
  },
  {
    name: "Page F",
    uv: 200,
  },
  {
    name: "Page G",
    uv: 800,
  },
];
export function ReactAreaChart() {
  return (
    <ResponsiveContainer width="100%" height="60%">
      <AreaChart
        width={200}
        height={60}
        data={data}
        margin={{
          top: 5,
          right: 0,
          left: 0,
          bottom: 5,
        }}
      >
        <Area type="monotone" dataKey="uv" stroke="#BCB8D0" fill="#E5E5E5" />
      </AreaChart>
    </ResponsiveContainer>
  );
}
