import React from "react";
import { Link } from "react-router-dom";
import { useParams } from "react-router-dom";

export function Header({ value, setCampaignName }) {
  const { id } = useParams();

  return (
    <div className="mt-3">
      <div className="row">
        <div className="col-sm-5">
          {id === undefined ? (
            
            <div className="pt-5 d-flex back-btn">
              <Link className="arrowbtn" to="/dashboard">
                {" < "}
              </Link>
              <p>Go To Dashboard</p>
            </div>
            
          ) : (
            <div className="pt-5 d-flex back-btn">
            <Link className="arrowbtn" to="/campaigns">
              {" < "}
            </Link>
            <p>Back</p>
          </div>
          )}
        </div>
        <div className="col-sm-5 text-end">
          <h5 className=" nameInput">
            <input
              type="text"
              className="nameInput mt-8"
              name="campaignName"
              value={value}
              placeholder="Campaign Name"
              onChange={(e) => setCampaignName(e.target.value)}
              autoComplete="off"
            />
          </h5>
        </div>
      </div>
      <hr />
    </div>
  );
}
