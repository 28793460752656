import React from "react";

import { Link } from "react-router-dom";
export function TotalTransaction({ className, TotalTransaction }) {
  return (
    <>
      <div className="totalTransaction-card">
        <div className={`card card-custom ${className}`}>
          <div className="card-body">
            <div className="d-flex mb-10">
              <h5>Total Transactions</h5>
              <div className="ml-auto">
                <Link to="transactions">See All Transactions</Link>
              </div>
            </div>
            <div className="main ">
              <div className="row">
                <div className="col-6  mb-5">
                  <h4>Name</h4>
                </div>

                <div className="col-6  mb-5">
                  <h4 className="text-center">Transactions</h4>
                </div>
              </div>
              {TotalTransaction?.length
                ? TotalTransaction.map((item, index) => {
                    return (
                      <div className="pt-5" key={index}>
                        <div className="row">
                          <div className="col-6">
                            <h5>{item.name}</h5>
                          </div>
                          <h5 className="col-6 text-center">{item.amount_total}</h5>
                        </div>
                      </div>
                    );
                  })
                : null}
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
