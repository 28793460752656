import React from "react";
import { BarChart, Bar, ResponsiveContainer } from "recharts";

const data = [
  {
    name: "Page A",
    uv: 300,
  },
  {
    name: "Page B",
    uv: 350,
  },
  {
    name: "Page C",
    uv: 500,
  },
  {
    name: "Page D",
    uv: 600,
  },
  {
    name: "Page E",
    uv: 550,
  },
  {
    name: "Page F",
    uv: 400,
  },
  {
    name: "Page G",
    uv: 600,
  },
  {
    name: "Page H",
    uv: 500,
  },
  {
    name: "Page I",
    uv: 650,
  },
  {
    name: "Page J",
    uv: 600,
  },
  {
    name: "Page K",
    uv: 700,
  },
  {
    name: "Page L",
    uv: 600,
  },
  {
    name: "Page M",
    uv: 650,
  },
  {
    name: "Page N",
    uv: 750,
  },
  {
    name: "Page O",
    uv: 650,
  },
];

export function ReactBarChart() {
  return (
    <ResponsiveContainer width="100%" height="70%">
      <BarChart data={data}>
        <Bar dataKey="uv" fill="#8C87A6" />
      </BarChart>
    </ResponsiveContainer>
  );
}
