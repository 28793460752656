import React from "react";
import { ProgressBar } from "react-bootstrap";

export function BreakdownByObject({ className, objectives }) {
  return (
    <>
      <div className={`card card-custom ${className}`}>
        <div className="card-body pb-1">
          <div className="d-flex mb-6">
            <h4 className="rate-desc">Breakdown by Objectives</h4>
            {/* <h4 className="ml-auto">See complete breakdown</h4> */}
          </div>

          {objectives.map((item, index) => (
            <div key={index}>
              <div className="mb-3">
                <ProgressBar variant="info" now={item.total} />
              </div>
              <div className="d-flex">
                <span className="mb-4">{item.title}</span>
                <p className="bar-percent ml-auto">{item.total}%</p>
              </div>
            </div>
          ))}
        </div>
      </div>
    </>
  );
}
