import React, { useMemo } from "react";
import Select from "react-select";
import countryList from "react-select-country-list";

function CountrySelector({ countryName, setCountryName }) {
  const options = useMemo(() => countryList().getData(), []);

  const changeHandler = (countryName) => {
    setCountryName(countryName?.label);
  };

  return (
    <Select
      options={options}
      value={{
        name: countryName ? countryName : "Select",
        label: countryName ? countryName : "Select",
      }}
      onChange={changeHandler}
    />
  );
}

export default CountrySelector;
