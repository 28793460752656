import axios from "axios";
// get url from env

export function getDashboard() {
  return axios.get("/admin/analytics");
}

export function getTransactions() {
  return axios.get("/payments/transactions?limit=10&page=1");
}
