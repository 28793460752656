import { createSlice } from "@reduxjs/toolkit";

const initialCampaignsState = {
  listLoading: false,
  actionsLoading: false,
  totalCount: 0,
  entities: null,
  industries: null,
  campaignForEdit: undefined,
  CampaignData: undefined,
  campaignCreate: undefined,
  lastError: null,
  campaignError: undefined,
};
export const callTypes = {
  list: "list",
  action: "action",
};

export const campaignsSlice = createSlice({
  name: "campaigns",
  initialState: initialCampaignsState,
  reducers: {
    catchError: (state, action) => {
      state.error = `${action.type}: ${action.payload.error}`;
      if (action.payload.callType === callTypes.list) {
        state.listLoading = false;
      } else {
        state.actionsLoading = false;
      }

      if (action.payload.errorType === "createError") {
        state.campaignError = action.payload?.errorData;
      }

      // state.campaignError = campaignError;
    },
    startCall: (state, action) => {
      state.error = null;
      state.campaignError = undefined;
      if (action.payload.callType === callTypes.list) {
        state.listLoading = true;
      } else {
        state.actionsLoading = true;
      }
    },
    // getCampaignById
    campaignFetched: (state, action) => {
      state.actionsLoading = false;
      state.campaignForEdit = action.payload.campaignForEdit;
      state.error = null;
    },
    // findCampaigns
    campaignsFetched: (state, action) => {
      const { totalCount, entities } = action.payload;
      state.listLoading = false;
      state.error = null;
      state.entities = entities;
      state.totalCount = totalCount;
    },
    // createCampaign
    campaignCreated: (state, action) => {
      const { campaign } = action.payload;
      state.actionsLoading = false;
      state.entities.push(action?.payload.campaign);
      state.campaignCreate = campaign;
    },
    // updateCampaign
    campaignUpdated: (state, action) => {
      state.error = null;
      state.actionsLoading = false;
      state.entities = state.entities.map((entity) => {
        if (entity.id === action.payload.campaign.id) {
          return action.payload.campaign;
        }
        return entity;
      });
    },
    // deleteCampaign
    campaignDeleted: (state, action) => {
      state.error = null;
      state.actionsLoading = false;
      // state.entities = state.entities.filter(
      //   (el) => el.id !== action.payload.id
      // );
    },
    // deleteCampaigns
    campaignsDeleted: (state, action) => {
      state.error = null;
      state.actionsLoading = false;
      // state.entities = state.entities.filter(
      //   (el) => !action.payload.ids.includes(el.id)
      // );
    },
    // campaignsUpdateState
    campaignsStatusUpdated: (state, action) => {
      state.actionsLoading = false;
      state.error = null;
      // const { ids, status } = action.payload;
      // state.entities = state.entities.map((entity) => {
      //   if (ids.findIndex((id) => id === entity.id) > -1) {
      //     entity.status = status;
      //   }
      //   return entity;
      // });
    },

    CampaignGet: (state, action) => {
      const { customCampaign } = action.payload;
      state.error = null;
      state.actionsLoading = false;
      state.CampaignData = customCampaign;
    },
    IndustryCreated: (state, action) => {
      const { industry } = action.payload;
      state.actionsLoading = false;
      state.error = null;
      state.entities.push(action.payload.industry);
      state.industryCreate = industry;
    },
    industriesFetched: (state, action) => {
      const { entities } = action.payload;
      state.error = null;
      state.actionsLoading = false;
      state.industries = entities;
    },
  },
});
