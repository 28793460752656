export const INDUSTRY_DATA = [
  { id: "1", label: "1-10 Years" },
  { id: "2", label: "11-20 Years" },
  { id: "3", label: "21-30 Years" },
  { id: "4", label: "31-40 Years" },
  { id: "5", label: "40+ Years" },
];

export const EXPERIENCE_DATA = [
  { id: "1", label: "1-10 Years" },
  { id: "2", label: "11-20 Years" },
  { id: "3", label: "21-30 Years" },
  { id: "4", label: "31-40 Years" },
  { id: "5", label: "40+ Years" },
];
export const blockInvalidChar = (e) =>
  ["e", "E", "+", "-"].includes(e.key) && e.preventDefault();

export const CE_AUTH = "CE_AUTH";

export const OPTIONS = [
  { title: "Conversion", id: "1" },
  { title: "Clicks", id: "2" },
  { title: "Impressions", id: "3" },
  // { title: "Lead", id: "4" },
];

export const BUDGET_RANGE = [
  { label: "0 to 10k USD", id: "1", min: 0, max: 10000 },
  { label: "10k to 50k USD", id: "2", min: 10001, max: 50000 },
  { label: "50k to 100k USD", id: "3", min: 50001, max: 100000 },
  { label: "100k USD + ", id: "4", min: 100001, max: 0 },
];

export const METRICS = {
  googleSearchEngine: {
    brandCampaign: {
      cpc: 0,
      ctr: 0,
      cr: 0,
    },
    competition: {
      cpc: 0,
      ctr: 0,
      cr: 0,
    },
    generic: {
      cpc: 0,
      ctr: 0,
      cr: 0,
    },
    rlsa: {
      cpc: 0,
      ctr: 0,
      cr: 0,
    },
    others: {
      cpc: 0,
      ctr: 0,
      cr: 0,
    },
  },
  bingSearchEngine: {
    brandCampaign: {
      cpc: 0,
      ctr: 0,
      cr: 0,
    },
    competition: {
      cpc: 0,
      ctr: 0,
      cr: 0,
    },
    generic: {
      cpc: 0,
      ctr: 0,
      cr: 0,
    },
    rlsa: {
      cpc: 0,
      ctr: 0,
      cr: 0,
    },
    others: {
      cpc: 0,
      ctr: 0,
      cr: 0,
    },
  },
  yandexSearchEngine: {
    brandCampaign: {
      cpc: 0,
      ctr: 0,
      cr: 0,
    },
    competition: {
      cpc: 0,
      ctr: 0,
      cr: 0,
    },
    generic: {
      cpc: 0,
      ctr: 0,
      cr: 0,
    },
    rlsa: {
      cpc: 0,
      ctr: 0,
      cr: 0,
    },
    others: {
      cpc: 0,
      ctr: 0,
      cr: 0,
    },
  },
  facebookSocialMedia: {
    engagement: {
      cpc: 0,
      ctr: 0,
      cr: 0,
      cpl: 0,
    },
    conversion: {
      cpc: 0,
      ctr: 0,
      cr: 0,
      cpl: 0,
    },
    reach: {
      cpc: 0,
      ctr: 0,
      cr: 0,
      cpl: 0,
    },
    remarketing: {
      cpc: 0,
      ctr: 0,
      cr: 0,
      cpl: 0,
    },
  },
  twitterSocialMedia: {
    engagement: {
      cpc: 0,
      ctr: 0,
      cr: 0,
      cpl: 0,
    },
    conversion: {
      cpc: 0,
      ctr: 0,
      cr: 0,
      cpl: 0,
    },
    reach: {
      cpc: 0,
      ctr: 0,
      cr: 0,
      cpl: 0,
    },
    remarketing: {
      cpc: 0,
      ctr: 0,
      cr: 0,
      cpl: 0,
    },
  },
  linkedinSocialMedia: {
    engagement: {
      cpc: 0,
      ctr: 0,
      cr: 0,
      cpl: 0,
    },
    conversion: {
      cpc: 0,
      ctr: 0,
      cr: 0,
      cpl: 0,
    },
    reach: {
      cpc: 0,
      ctr: 0,
      cr: 0,
      cpl: 0,
    },
    remarketing: {
      cpc: 0,
      ctr: 0,
      cr: 0,
      cpl: 0,
    },
  },
  snapchatSocialMedia: {
    engagement: {
      cpc: 0,
      ctr: 0,
      cr: 0,
      cpl: 0,
    },
    conversion: {
      cpc: 0,
      ctr: 0,
      cr: 0,
      cpl: 0,
    },
    reach: {
      cpc: 0,
      ctr: 0,
      cr: 0,
      cpl: 0,
    },
    remarketing: {
      cpc: 0,
      ctr: 0,
      cr: 0,
      cpl: 0,
    },
  },
  googleDisplayEngine: {
    branding: {
      cpc: 0,
      ctr: 0,
      cr: 0,
    },
    reach: {
      cpc: 0,
      ctr: 0,
      cr: 0,
    },
    conversion: {
      cpc: 0,
      ctr: 0,
      cr: 0,
    },
    remarketing: {
      cpc: 0,
      ctr: 0,
      cr: 0,
    },
  },
  crietoDisplayEngine: {
    remarketingReach: {
      cpc: 0,
      ctr: 0,
      cr: 0,
    },
    remarketingConversion: {
      cpc: 0,
      ctr: 0,
      cr: 0,
    },
  },
};
